import React from "react";
import "gantt-task-react/dist/index.css";

import {
  // Customizable Area Start
  // Customizable Area End
  Box,
} from "@mui/material";
// Customizable Area Start
import {
  FormControl,
  Typography,
  Select,
  Modal,
} from "@material-ui/core"
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import "@mui/styles";
import Layout from "../../dashboard/src/Layout.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GanttChartController, {
  configJSON,
  Props,
} from "./GanttChartController";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { emptyStateImage, minusIcon, plusIcon } from "./assets";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class GanttChart extends GanttChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  configOptions: any = {
    chart: {
      type: 'rangeBar',
      fontFamily: "Lato",
      padding: 0,
      zoom: {
        enabled: true, 
        type: 'x',  
        autoScaleYaxis: false,  
        allowMouseWheelZoom: false,  
      },
      toolbar: {
        tools: {
          zoomin: false,
          zoomout: false,
          pan: true,
          zoom: false,
          reset: false,
        },
        autoSelected:'pan',
        offsetY: -10
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '20px',
        borderRadius: 2,
      },
      ramgeBarGroupRows:true,
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: true,
      theme: 'light',
      style: {
        fontSize: '14px',
        fontFamily: 'Lato',
      },
      custom: ({ seriesIndex, dataPointIndex, w }: any) => {
        const data = w.config.series[seriesIndex].data[dataPointIndex];
        const label = data.x;
        const date = data.y;
        const assigneeList:{id: number; image: string; full_name:string;}[] = data.assignees ?? [];
        let imageArr = "";

        assigneeList.forEach(item => {
          if (item.image) {
            imageArr += `<img src=${item.image} width="40px" height="40px" style="border-radius: 20px; border: 3px solid #FFF;" />`
          } else if (item.full_name) {
            imageArr += `<div style="width: 40px; height:40px; border: 3px solid #FFF; border-radius: 20px; background: lightgrey; align-items: center; justify-content: center; display:flex;"><p style="font-size: 1rem; color: #34373A; font-family: Lato;">${item.full_name[0].toUpperCase()}</p></div>`
          }
        })

        return `
          <div style="padding: 10px; background-color: #fff; border: 1px solid #ccc; border-radius: 5px; font-family: Lato">
            <strong>Sub category:</strong> ${label}<br /><br />
            <strong>Date:</strong> ${moment(date[0]).format('DD MMM YY')} - ${moment(date[1]).format('DD MMM YY')} <br /><br />
            ${imageArr}
          </div>
        `;
      },
    },
    xaxis: {
      type: 'datetime',
      position: 'top',
      title: {
        style: {
          fontFamily: 'Lato',
          fontSize: '14px'
        },
      },
      labels: {
        formatter: function (value: any) {
          return moment(value).format('DD MMM YY')
        },
      },
    },
    yaxis: {
      title: {
        style: {
          fontFamily: 'Lato',
          fontSize: '12px',
          lineHeight: '19.2px',
          color: '#34373A',
          fontWeight: 400,
        },
      },
    },
    stroke: {
      width: 0,
    },
    fill: {
      type: 'solid',
      opacity: 1,
      colors: ["#D3D3D3", "#6EEA95"],
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      formatter: function (val:any, {seriesIndex, dataPointIndex, w}:any) {
        const data = w.config.series[seriesIndex].data[dataPointIndex];
        if(seriesIndex === 0){
          return 'Planned';
        }
        if(data.task_progress_percentage_avg > 0){
          return `Actual - ${data.task_progress_percentage_avg}%`;
        }
        return 'Actual';
      },
      style: {
        colors: ['#34373A'],
        fontSize: "10px",
        fontFamily: 'Lato',
        fontWeight: 600,
        textAlign: 'center'
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
    },
    annotations: {
      xaxis: [
        {
          x: new Date().getTime(),
          borderColor: "#43D270",
          strokeDashArray: 0,
          label: {
            text: "Today",
            orientation: "horizontal",
            offsetX: 19,
            style: {
              background: "#43D270",
              color: "#34373A",
              fontSize: "12px",
              fontWeight: 700,
              fontFamily: "Lato",
            },
          },
        },
      ],
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
        }
      },
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {this.state.listCount === 0 && this.user_type !== 'portfolio_manager' ? (
            <Layout>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'center',
                justifyContent: 'center',
                marginTop: '20%'
              }}>
                <img src={emptyStateImage} style={{width: '165px', height: '152px'}} />
                <Typography style={{fontSize: '16px', lineHeight:'20px', color:'#34373A', fontFamily: 'Lato', marginTop: '29px', textAlign:'center'}}>
                  No action plan found. Reach out to your Portfolio Manager to create the action plan for your organization.
                </Typography>
              </Box>
            </Layout>
          ):(
            <Layout data-test-id="layout-view">
            <Box style={{ marginLeft: '44px', marginTop: '32px', marginRight: '44px' }}>
              <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Typography style={webStyle.actionText}>{configJSON.actionPlanText}</Typography>
              </Box>
              <Typography style={webStyle.actionDescription}>{configJSON.actionPlanDescription}</Typography>
            </Box>
            <hr color="#EBEBEB" />
                <Box style={{ marginLeft: '10px', marginRight: "10px" }} test-data-id="category-list">
                  {Object.keys(this.state.graphObj).map((key, index) => {
                    return (
                      <Box key={index} data-test-id={`${index}`}>
                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <Box
                            style={{ width: '16%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            onClick={()=>this.categoryPressHandler(key)}
                          >
                            <img
                              src={(this.state.activeCateogry === key && this.state.chartData[0].data.length > 0) ? minusIcon : plusIcon}
                              style={{ width: '16px', height: '16px', marginRight: '7px' }}
                            />
                            <Typography style={webStyle.categoryText}>{key}</Typography>
                          </Box>
                          <Box style={{ height: '8px', background: this.getBackgroundColor(key), display: 'flex', flex: 1 }}></Box>
                        </Box>
                        {(this.state.activeCateogry === key && this.state.chartData[0].data.length > 0) && (
                          <ReactApexChart
                            options={this.configOptions}
                            series={this.state.chartData}
                            type="rangeBar"
                            height={'600px'}
                          />
                        )}
                      </Box>
                    )
                  })}
                </Box>
            <hr color="#EBEBEB" />
            <Box data-test-id='go-to-btn' style={webStyle.btnContainer} onClick={this.navigateToDoList}>
              <Typography style={webStyle.btnText}>Go to Task List ({this.state.listCount})</Typography>
            </Box>
            <Modal
              open={this.state.errModal}
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <ErrDiv style={webStyle.errDiv}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                  <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                    <Typography style={webStyle.errTypo}>Log your climatic goals to track them on your dashboard</Typography>
                    <Typography style={webStyle.errMsg}>Data for climate insights comes from your log entries. It seems like your organization didn't log enough data for us to showcase any insights. Please enter log data in order to leverage CarbonZeroed full potential. </Typography>
                    <div style={{ paddingLeft: "27px", paddingBottom: "20px", paddingRight: "27px" }} >
                      <CustomBtn data-test-id="errModalId" onClick={()=> this.navigateToClimaticGoals()}> + Log Your First Climate Goals</CustomBtn>
                    </div>
                  </div>

                </div>
              </ErrDiv>
            </Modal>
          </Layout>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  actionText: {
    fontSize: '22px',
    fontWeight: 700,
    color: '#34373A',
    lineHeight: '27.5px',
    fontFamily: "Lato",
  },
  actionDescription: {
    fontSize: '12px',
    color: '#7D7D7F',
    lineHeight: '19.2px',
    maxWidth: '75%',
    marginBottom: '33px',
    fontFamily: "Lato",
  },
  menuItems: {
    maxWidth: '92px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: "1px !important",
    fontSize: '10px'
  } as React.CSSProperties,
  arrowUpIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    height: '20px',
    color: '#000'
  },
  errDiv: {
    width: '60%',
    height: '198px',
    borderRadius: '12px',
    background: '#ECFBF1',
  },
  errTypo: {
    color: 'var(--neutral-100, #101010)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%',
    paddingBottom: "12px",
    paddingTop: "25px",
    paddingLeft: "27px",
    paddingRight: "27px"
  },
  errMsg: {
    width: '100%',
    color: '#34373A',
    fontFamily: 'Lato',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    paddingBottom: "22px",
    paddingLeft: "27px",
    paddingRight: "27px"
  },
  btnContainer:{
    width: '195px',
    height: '40px',
    backgroundColor: '#43D270',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '34px',
    marginTop: '24px',
    marginBottom: '24px',
    cursor: 'pointer',
  },
  btnText:{
    fontSize: '12px',
    lineHeight: '19.2px',
    fontWeight: 700,
    fontFamily: 'Inter',
    cursor: 'pointer',
  },
  categoryText:{
    fontSize: '12px',
    lineHeight: '19.2px',
    fontWeight: 700,
    fontFamily: 'Lato',
    color:  '#34373A',
  } as React.CSSProperties,
};

const ErrDiv = styled(Box)({
  '@media(max-width: 400px)': {
      height: "68%  !important",
  },
});

const CustomBtn = styled(Box)({ 
  textTransform: "capitalize",
   display: 'flex',
   width: '200px',
   height: '40px',
   padding: '8px 8px 8px 12px',
   justifyContent: 'center',
   alignItems: 'center',
   gap: '4px',
   borderRadius: '8px',
   backgroundColor: '#43D270',
   color: '#34373A',
   textAlign: 'center',
   fontFamily: 'Inter',
   fontSize: '12px',
   fontStyle: 'normal',
   fontWeight: 700,
   lineHeight: '160%',
 })

const AnalizeDropdown = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  maxWidth: "100px !important",
  border: '1px solid rgba(0, 0, 0, 0.2)',
})

const AnalizeSelectField = styled(Select)({
  height: "36px",
  borderRadius: "8px",
  padding: "8px !important;",
  "& *": {
      background: "#fff !important;",
      fontFamily: "Lato",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "19.2px",
      color: '#34373A',
      paddingRight: '0px !important'
  },
  '&:focus': {
      borderColor: '#cccccc',
  },
  '& .MuiInputBase-input-209':{
    color:'#34373A',
    fontSize:'12px',
    lineHeight: '19.2px',
    fontFamily:'Lato',
    fontWeight:600,
  }
})
// Customizable Area End
